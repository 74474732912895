<template>
  <Layout tituloPagina="Sistema | Ajustes | General">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes generales
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label>Nombre de la empresa</label>
            <input
              type="text"
              name="nombre_empresa"
              placeholder="Nombre de la empresa"
              v-model="nombre_empresa"
              class="form-control"
            />
          </div>

          <div class="col-md-3">
            <label>Impuestos</label>
            <div class="input-group">
              <input
                class="valida form-control text-right"
                v-model="iva"
              />
              <div class="input-group-text">
                <i class="fas fa-percent"></i>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label>Moneda</label>
            <select class="form-select" v-model="moneda.id_moneda" name="moneda">
              <option :value="null">Seleccionar moneda</option>
              <option
                v-for="moneda in monedas"
                :key="moneda.id"
                :value="moneda.id"
              >
                ({{moneda.codigo}}) - {{moneda.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Fecha y hora</label>
            <input type="text" v-model="fecha_y_hora" 
              class="form-control"
              readonly
            />
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-3">
            <label>Prefijo numérico cliente</label>
            <input
              type="text"
              class="form-control"
              title="El prefijo no puede ser mayor a 6 caracteres"
              placeholder="PREF12"
              name='prefnumcli'
              v-model="prefnumcli"
              style="text-transform:uppercase;"
              maxlength="6"
            >
          </div>
          <div class="col-md-3">
            <label>Código de pais para telefonos</label>
            <select class="form-select" v-model="id_pais">
              <option :value="null">Seleccionar</option>
              <option
                v-for="pais in paises"
                :key="pais.id"
                :value="pais.id"
              >
                {{pais.nombre}} ( {{pais.prefijo_telefonico}} )
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Zona horaria</label>
            <select class="form-select" v-model="timezone">
              <option :value="null">Seleccionar</option>
              <option
                v-for="(timezone, index) in timezones"
                :key="index"
                :value="timezone"
              >
                {{timezone}}
              </option>
            </select>
          </div>
        </div>
        <br />
        <h5>
          Posición inicial de la empresa
          <small class="m-l-sm">
            (la posicion inicial de cada nuevo CPE)
          </small>
        </h5>
        <GoogleMap
          ref="mapa"
          :api-key="apikeyGoogle"
          :center="posicionInicial"
          style="width: 100%; height:500px"
          :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
          :zoom="mapaZoom"
        >
          <Marker
            ref="marker"
            :options="{ 
              position: posicionInicial,
              title: 'Empresa',
              icon: markerIcon,
              draggable: true
            }"
            @dragend="mostrarPosicion($event)"
          ></Marker>
        </GoogleMap>
        <br>
        <div class="row">
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-text">
                <span class="d-none d-sm-block">
                  Latitud,Longitud
                </span>
                <span class="d-block d-sm-none">
                  Lat,Lng
                </span>
              </span>
              <input
                type="text"
                class="form-control text-right"
                v-model="lat_lng"
                :readonly="bloquear_lat_lng"
                @change="actualizarPosicion()"
              />
              <button class="btn btn-light" 
                @click="bloquear_lat_lng = !bloquear_lat_lng"
              >
                <i
                  class="mdi"
                  :class="{
                    'mdi-lock-outline': bloquear_lat_lng,
                    'mdi-lock-open-variant-outline': !bloquear_lat_lng
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right pb-4">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import { GoogleMap, Marker } from "vue3-google-map"
import markerIcon from '@/assets/img/iconoEmpresa.png'
import SistemaSrv from '@/services/SistemaSrv.js'
import axios from 'axios'
import moment from 'moment'
import { todoMethods } from "@/state/helpers"
export default {
  name: 'General',
  components: { Layout, GoogleMap, Marker },
  data() {
    return {
      nombre_empresa: '',
      iva: 0,
      google: null,
      mapa: null,
      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      lat_lng: 0,
      bloquear_lat_lng: true,
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      moneda: {
        id_moneda: null,
        nombre: '',
        simbolo: '',
        codigo: '',
        activo: true
      },
      monedas: [],
      prefnumcli: '',
      paises: [],
      bandera_spinner: false,
      prefijo_telefonico: null,
      timezone: null,
      timezones: [],
      id_pais: null,
      pais:{
        id: null,
        nombre: '',
        porefijo_telefonico: null
      },
      idInterval: null,
      fecha_y_hora: null,
    }
  },

  computed: {
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    }
  },

  created: function(){
    var self = this

    SistemaSrv.posicionInicial().then(response => {
      let posicion = response.data

      self.posicionInicial = {
        lat: parseFloat(posicion.latitud),
        lng: parseFloat(posicion.longitud)
      }

      self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)
    }).catch(error => {
      let mensaje
      try {
        mensaje = error.response.data.message
      } catch (e) {
        mensaje = ''
      }
      mensaje != '' ? iu.msg.error(mensaje) : null
      console.log(error)
    })

    self.cargarIdMoneda()
    self.cargarMonedas()
    self.cargarPaises()
    self.cargarTimezones()
    self.refrescarAjustes()
  },

  watch: {
    id_pais: function(newId, oldId){
      var self = this
      self.cargarPais()
    }
  },

  methods: {
    ...todoMethods,
    actualizar() {
      let self = this, ajustes = {}
      
      ajustes = {
        nombre_empresa: self.nombre_empresa,
        iva: self.iva,
        prefnumcli: self.prefnumcli,
        posicion_inicial: `${self.posicionInicial.lat},${self.posicionInicial.lng}`,
        prefijo_telefonico: self.pais.prefijo_telefonico,
        id_pais: self.id_pais,
        timezone: self.timezone
      }

      self.bandera_spinner = true

      let prom1 = SistemaSrv.actualizar(ajustes)
      let prom2 = SistemaSrv.actualizarIdMoneda(self.moneda.id_moneda)

      axios.all([prom1, prom2]).then(response => {
        iu.msg.success('Datos actualizados')
        self.cargarIdMoneda()
        self.refrescarAjustes()
        self.cargarMonedaSistema()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarPosicion: function() {
      var self = this, array = self.lat_lng.split(',')

      if(array.length == 1 || array.length > 2) {
        iu.msg.warning("No es una coordenada válida, ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.posicionInicial.lat)+','+parseFloat(self.posicionInicial.lng)
        return
      }
      let latitud = parseFloat(array[0]), longitud = parseFloat(array[1])

      if(!latitud || !longitud) {
        iu.msg.warning("Latitud o longitud no son coordenadas válidas; ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.posicionInicial.lat)+','+parseFloat(self.posicionInicial.lng)
        return
      }

      self.posicionInicial = {
        lat: latitud,
        lng: longitud
      }

      self.lat_lng = latitud+','+longitud
    },

    cargarMonedaSistema() {
      var self = this
      SistemaSrv.monedaJSON().then(response => {
        self.actualizarMonedaSistema({ moneda: response.data })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la moneda del sistema'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarIdMoneda: function () {
      var self = this

      SistemaSrv.idMoneda().then(response => {
        self.moneda.id_moneda = response.data ? parseInt(response.data) : null
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }

        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarMonedas: function() {
      var self = this

      SistemaSrv.monedasJSON().then(response => {
        self.monedas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPaises: function() {
      var self = this
      
      let params = {
        datos_solicitados: ['id','nombre','prefijo_telefonico']
      }

      SistemaSrv.paisesJSON(params).then(response => {
        self.paises = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los paises'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTimezones: function() {
      var self = this

      SistemaSrv.timezonesJSON().then(response => {
        self.timezones = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los timezones'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPais() {
      var self = this

      let pais = self.paises.find(pais => {
        return pais.id == self.id_pais
      })

      if(pais == null) return

      self.pais = pais
    },

    mostrarPosicion: function(event) {
      var self = this

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      self.lat_lng = event.latLng.lat()+','+event.latLng.lng()
    },

    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes(['nombre_empresa', 'iva', 'prefnumcli', 'prefijo_telefonico', 'id_pais', 'timezone', 'fecha_y_hora']).then(response => {
        let ajustes = response.data
        self.nombre_empresa = ajustes.nombre_empresa
        self.iva = ajustes.iva
        self.prefnumcli = ajustes.prefnumcli
        self.prefijo_telefonico = ajustes.prefijo_telefonico
        self.id_pais = ajustes.id_pais
        self.timezone = ajustes.timezone

        clearInterval(self.idInterval)
        self.refrescarFechaYHora()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los ajustes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarFechaYHora() {
      let self = this
      SistemaSrv.fechaYHora().then(response => {
        let formato = 'DD/MM/YYYY HH:mm:ss' 
        self.fecha_y_hora = moment(response.data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')

        self.idInterval = setInterval(function(){
          let dt = moment(self.fecha_y_hora, formato).add(1, 'second')
          self.fecha_y_hora = dt.format(formato)
        }, 1000) 
      })
    }
  }
}
</script>

<style scoped>

</style>